import { Notyf } from 'notyf';
import { datadogRum } from '@datadog/browser-rum';

// DataDog configuration
if (process.env.RAILS_ENV !== 'development') {
  datadogRum.init({
    // These get exposed on frontend anyway, so there's no point in trying to obfuscate the id
    applicationId: '3c077413-a6ad-4415-bd6d-9f3acb386f40',
    clientToken: 'pub236eacb6216591c4c1883d709a30b6da',
    site: 'datadoghq.com',
    service: 'top-techs-plus',
    env: process.env.RAILS_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // testing for gitlab to see if it deploys version
    version: process.env.DD_VERSION,
    sessionSampleRate: (process.env.RAILS_ENV === 'development') ? 0 : 100,
    sessionReplaySampleRate: 10, // out of 100% sessions, 20% will be record
    trackUserInteractions: (process.env.RAILS_ENV === 'production'),
    trackResources: (process.env.RAILS_ENV === 'production'),
    trackLongTasks: (process.env.RAILS_ENV === 'production'),
  });
}

const { id, dataset } = document.body;
const { flashNotice, flashError } = dataset;

// check for flash
const notyf = new Notyf({
  duration: 10000,
  position: {
    x: 'right',
    y: 'top',
  },
  dismissible: true,
});

if (flashError) {
  notyf.error(flashError);
} else if (flashNotice) {
  notyf.success(flashNotice);
}

// Import Shared
if (document.querySelector('#hero-overlay')) {
  import('../js/views/shared/hero');
}


// Import Pages
if (id === 'dealership_objectives-index') {
  import('../js/views/dealership_objectives/index');
} else if (id === 'home-index') {
  import('../js/views/home/index');
}

// Setup header
const profileMenuLink = document.getElementById('profile-menu-link');
const profileDropdown = document.getElementById('profile-dropdown');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', (e) => {
    // e.preventDefault();
    profileDropdown.classList.toggle('show');
  });
}

